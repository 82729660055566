import { Paper } from '@mui/material';
import { useLocalization, LocalizedLink } from 'gatsby-theme-i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useArticles } from '../../helpers/use-articles';
import * as css from './articles.module.css';

const ArticleCategory = ({
  title,
  articles,
  slug,
}: {
  title: string;
  articles: any[];
  slug: string;
}) => {
  return (
    <div>
      <h2>{title}</h2>
      {articles.map((a, idx) => {
        const isSelected = slug === a.childMdx.frontmatter.slug;
        const className = isSelected ? css.selectedArticle : css.article;

        const linkClassName = isSelected ? css.selectedLink : css.link;

        return (
          <div className={className} key={idx}>
            <LocalizedLink
              to={a.childMdx.frontmatter.slug}
              className={linkClassName}
            >
              {a.childMdx.frontmatter.title}
            </LocalizedLink>
          </div>
        );
      })}
    </div>
  );
};

export const ArticlesMenu = ({ slug }: { slug: string }) => {
  const { locale } = useLocalization();
  const { t } = useTranslation('articles');

  const { sortedGeneral, sortedPractceManagement, sortedResearch } =
    useArticles(locale);

  return (
    <div className={css.container}>
      <h1>{t('articles') as string}</h1>
      <ArticleCategory
        slug={slug}
        title={t('general')}
        articles={sortedGeneral}
      />
      <ArticleCategory
        slug={slug}
        title={t('practiceManagement')}
        articles={sortedPractceManagement}
      />
      <ArticleCategory
        slug={slug}
        title={t('research')}
        articles={sortedResearch}
      />
    </div>
  );
};
