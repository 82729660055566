import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ArticlesMenu } from '../../components/ArticlesMenu';
import { Header } from '../../components/Header';
import * as css from './article-template.module.css';
import { Paper, ThemeProvider } from '@mui/material';
import { theme } from '../../themes';
import { Footer } from '../../components/Footer';

const ArticleTemplate = ({ data, pageContext, locale }: any) => {
  const { t } = useTranslation('articles');
  return (
    <ThemeProvider theme={theme}>
      <div className={css.container}>
        <Header />
        <div className={css.contentContainer}>
          <Paper className={css.articlesMenu} elevation={1}>
            <ArticlesMenu slug={pageContext.slug} />
          </Paper>
          <div className={css.article}>
            <div>
              {data.mdx ? (
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              ) : (
                <div>{t('notTranslated')}</div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
      }
      body
    }
  }
`;
